import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16">{{ data.message }}</div>
    <div mat-dialog-actions>
    
    &nbsp;
    <span fxFlex></span>
    </div>`,
})
export class AppComfirm2Component {
  constructor(
    public dialogRef: MatDialogRef<AppComfirm2Component>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}
}